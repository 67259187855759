<template>
  <div class="main-proximas fade-in-up">
    <b-jumbotron class="title-page title_page">
      <h1 class="text-center font-weight-bold">Próximas Turmas</h1>
    </b-jumbotron>
    <BreadCpv />

    <b-container>
      <div @click="isToggleExtensivo" variant="transparent" class="button-toggle">
        <span> <span class="strong">Extensivo</span> - Presencial ou EaD </span>
        <div class="button_toggle_icon">
          <i class="fa-solid fa-minus" v-if="isExtensivoCollapsed"></i>
          <i class="fa-solid fa-plus" v-else></i>
        </div>
      </div>
      <b-collapse v-model="isExtensivoCollapsed" id="collapse1">
        <div class="extensivo-content">
          <div v-if="activetabExtensivo == 'extensivoPresencial'">
            <keep-alive>
              <b-row>
                <b-col cols="12" md="4" lg="3" v-for="(curso, index) in coursesExtensivo" :key="index">
                  <!-- VER AQUI O QUE PASSO DE VARIAVEIS -->

                  <!-- <router-link :to="`/proximasturmas/${curso.slug}`" class="router-link-no-decoration"> -->
                  <router-link
                    :to="{ name: 'detalhe-do-curso', params: { slug: curso.slug } }"
                    class="router-link-no-decoration"
                  >
                    <b-card no-body class="card-course mt-3">
                      <div class="header-date d-flex justify-content-between align-items-baseline">
                        <div>
                          <i class="fa-regular fa-calendar-check mr-1"></i>
                          <span>{{ curso.data_de_inicio | formatShortDate }}</span>
                        </div>
                        <b-badge variant="dark" class="text-white">{{ curso.ano }}</b-badge>
                      </div>
                      <div class="title-course">
                        <span>{{ curso.nome_site }}</span>
                      </div>
                      <div class="footer-info">
                        <div class="hour">
                          <i class="fa-regular fa-clock-seven"></i>
                          <span>{{ curso.short_horario }}</span>
                        </div>
                        <div class="location">
                          <i class="fa-sharp fa-regular fa-location-dot map-dot"></i>
                          <span>{{ curso.unidades }}</span>
                        </div>
                      </div>
                    </b-card>
                  </router-link>
                </b-col>
              </b-row>
            </keep-alive>
          </div>
          <div v-if="activetabExtensivo == 'extensivoEad'">
            <keep-alive>
              <b-row>
                <b-col cols="12" md="4" lg="3" v-for="(curso, index) in coursesExtensivo" :key="index">
                  <router-link
                    :to="{ name: 'detalhe-do-curso', params: { slug: curso.slug } }"
                    class="router-link-no-decoration"
                  >
                    <b-card no-body class="card-course mt-3">
                      <div class="header-date d-flex justify-content-between align-items-baseline">
                        <div>
                          <i class="fa-regular fa-calendar-check mr-1"></i>
                          <span>{{ curso.data_de_inicio | formatShortDate }}</span>
                        </div>
                        <b-badge variant="dark" class="text-white">{{ curso.ano }}</b-badge>
                      </div>
                      <div class="title-course">
                        <span>{{ curso.nome_site }} EAD</span>
                      </div>
                      <div class="footer-info">
                        <div class="hour">
                          <i class="fa-regular fa-clock-seven"></i>
                          <span>{{ curso.short_horario }}</span>
                        </div>
                        <div class="location">
                          <i class="fa-sharp fa-regular fa-location-dot map-dot"></i>
                          <span>{{ curso.unidades }}</span>
                        </div>
                      </div>
                    </b-card>
                  </router-link>
                </b-col>
              </b-row>
            </keep-alive>
          </div>
          <div v-if="activetabExtensivo == 'extensivoEad'"></div>
        </div>
      </b-collapse>
      <div @click="isTogglePrimeiroSemestre" variant="transparent" class="button-toggle">
        <span> <span class="strong">1º Semestre</span> - Presencial ou EaD </span>
        <div class="button_toggle_icon">
          <i class="fa-solid fa-minus" v-if="isPrimeiroSemestreCollapsed"></i>
          <i class="fa-solid fa-plus" v-else></i>
        </div>
      </div>
      <b-collapse v-model="isPrimeiroSemestreCollapsed" id="collapse1">
        <div class="extensivo-content">
          <b-row>
            <b-col cols="12" md="4" lg="3" v-for="(curso, index) in coursesSemestre1" :key="index">
              <router-link :to="`/proximasturmas/${curso.slug}`" class="router-link-no-decoration">
                <b-card no-body class="card-course mt-3">
                  <div class="header-date d-flex justify-content-between align-items-baseline">
                    <div>
                      <i class="fa-regular fa-calendar-check mr-1"></i>
                      <span>{{ curso.data_de_inicio | formatShortDate }}</span>
                    </div>
                    <b-badge variant="dark" class="text-white">{{ curso.ano }}</b-badge>
                  </div>
                  <div class="title-course">
                    <span>{{ curso.nome_site }}</span>
                  </div>
                  <div class="footer-info">
                    <div class="hour">
                      <i class="fa-regular fa-clock-seven"></i>
                      <span>{{ curso.short_horario }}</span>
                    </div>
                    <div class="location">
                      <i class="fa-sharp fa-regular fa-location-dot map-dot"></i>
                      <span>{{ curso.unidades }}</span>
                    </div>
                  </div>
                </b-card>
              </router-link>
            </b-col>
          </b-row>
        </div>
      </b-collapse>

      <div @click="isToggleSegundoSemestre" variant="transparent" class="button-toggle">
        <span> <span class="strong">2º Semestre</span> - Presencial ou EaD </span>
        <div class="button_toggle_icon">
          <i class="fa-solid fa-minus" v-if="isSegundoSemestreCollapsed"></i>
          <i class="fa-solid fa-plus" v-else></i>
        </div>
      </div>
      <b-collapse v-model="isSegundoSemestreCollapsed" id="collapse1">
        <div class="extensivo-content">
          <b-row>
            <b-col cols="12" md="4" lg="3" v-for="(curso, index) in coursesSemestre2" :key="index">
              <router-link :to="`/proximasturmas/${curso.slug}`" class="router-link-no-decoration">
                <b-card no-body class="card-course mt-3">
                  <div class="header-date d-flex justify-content-between align-items-baseline">
                    <div>
                      <i class="fa-regular fa-calendar-check mr-1"></i>
                      <span>{{ curso.data_de_inicio | formatShortDate }}</span>
                    </div>
                    <b-badge variant="dark" class="text-white">{{ curso.ano }}</b-badge>
                  </div>
                  <div class="title-course">
                    <span>{{ curso.nome_site }}</span>
                  </div>
                  <div class="footer-info">
                    <div class="hour">
                      <i class="fa-regular fa-clock-seven"></i>
                      <span>{{ curso.short_horario }}</span>
                    </div>
                    <div class="location">
                      <i class="fa-sharp fa-regular fa-location-dot map-dot"></i>
                      <span>{{ curso.unidades }}</span>
                    </div>
                  </div>
                </b-card>
              </router-link>
            </b-col>
          </b-row>
        </div>
      </b-collapse>
    </b-container>
  </div>
</template>

<script>
// manter esse import Vue aqui. Estou vendo se para de dar um erro estranho.
import Vue from "vue";
import BreadCpv from "@/components/global/bread-cpv.vue";
export default {
  name: "courses-index",
  components: { BreadCpv },
  data() {
    return {
      isLoading: false,
      newCourses: null,
      activetabExtensivo: "extensivoPresencial",

      coursesExtensivo: [],
      coursesSemestre1: [],
      coursesSemestre2: [],

      isExtensivoCollapsed: true,
      isPrimeiroSemestreCollapsed: true,
      isSegundoSemestreCollapsed: false,

      slug: "",
    };
  },
  created: function () {
    this.getCourses();
    this.$waitForEcho()
      .then(() => {
        this.$joinChannel("proximasturmas");
      })
      .catch((error) => {
        console.error("Erro ao conectar ao Echo:", error);
      });
  },
  beforeDestroy: function () {
    this.$leaveChannel("proximasturmas");
  },
  methods: {
    isToggleExtensivo() {
      this.isExtensivoCollapsed = !this.isExtensivoCollapsed;
      this.isPrimeiroSemestreCollapsed = false;
      this.isSegundoSemestreCollapsed = false;
    },
    isTogglePrimeiroSemestre() {
      this.isPrimeiroSemestreCollapsed = !this.isPrimeiroSemestreCollapsed;
      this.isExtensivoCollapsed = false;
      this.isSegundoSemestreCollapsed = false;
    },
    isToggleSegundoSemestre() {
      this.isSegundoSemestreCollapsed = !this.isSegundoSemestreCollapsed;
      this.isPrimeiroSemestreCollapsed = false;
      this.isExtensivoCollapsed = false;
    },
    getCourses() {
      axios
        .get(`sitecpv/cursinho/proximasturmas/getCourses`)
        .then((response) => {
          this.coursesExtensivo = response.data.coursesExtensivo;
          this.coursesSemestre1 = response.data.coursesSemestre1;
          this.coursesSemestre2 = response.data.coursesSemestre2;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
  },
};
</script>

<style scoped>
.fade-in-up {
  -webkit-animation: fadeIn 0.4s ease-in-out;
  -moz-animation: fadeIn 0.4s ease-in-out;
  -o-animation: fadeIn 0.4s ease-in-out;
  animation: fadeIn 0.4s ease-in-out;
}

.main-proximas {
  margin: 0px 0px 80px;
  font-family: "Clan-Book Book", "FF Clan Pro", sans-serif;
}

.title_page {
  margin: -8px 0px 55px !important;
  background-repeat: repeat;
  background-position: center;
  background-color: rgb(239, 72, 87);
  background-image: url("https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/cpv-fundo-4.png");
  background-size: 30%;
  /* background-attachment: fixed; */
  object-fit: cover;
  border-radius: 0;
  font-family: "FF Clan Pro", sans-serif;
}

.title_page h1 {
  font-size: 51px;
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
}

.button-toggle {
  margin-top: 0px;
  margin-bottom: 0px;

  background: #f8f8f844;
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;

  padding: 15px 25px;
  height: 56px;

  border: 1px solid #e9e9e9;
  border-radius: 3px;
}

.button-toggle span {
  font-size: 15.5px;
  color: rgb(58, 28, 83);
  font-weight: 500;
  font-family: "FF Clan Pro", sans-serif;
  font-size: 17px;
}

.button-toggle span .strong {
  font-weight: 700;
}

.button-toggle .button_toggle_icon {
  display: flex;
  flex: content;
  justify-content: flex-end;
}

.button-toggle .button_toggle_icon {
  font-size: 17px;
  color: rgb(239, 72, 87);
  font-weight: 300 !important;
}

.extensivo-content {
  padding: 20px 20px;
  background-color: #f8f8f844;
  border: 1px solid #e9e9e9;
  border-top: 0;
  border-radius: 3px;

  font-family: "Clan-Book Book", sans-serif;
}

.extensivo-content .router-link-no-decoration {
  text-decoration: none;
}

.extensivo-content .card-course {
  background-color: transparent;
  border: 1px solid #e9e9e9;
  border-radius: 3px;
}

.extensivo-content .card-course .header-date {
  padding: 10px;
  margin: 0;
  display: block;
  color: rgb(58, 28, 83);
}

.extensivo-content .card-course .header-date span {
  font-size: 14px;
  font-weight: 800;
  color: rgb(58, 28, 83);
}

.extensivo-content .card-course .title-course {
  padding: 0px 10px 10px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: left;
}

.extensivo-content .card-course .title-course span {
  font-size: 16px;
  font-weight: 700;
  color: rgb(239, 72, 87);
  letter-spacing: -0.004em;
}

.footer-info {
  padding: 10px;
  background-color: rgb(239, 72, 87);
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.footer-info .hour span,
.location span {
  line-height: 20px;
  font-size: 12px;
  font-weight: 700;
  color: #ffffff;
  /* letter-spacing: 0.02rem; */
}

.footer-info .hour i,
.location i {
  margin-right: 4px;
  line-height: 20px;
  font-size: 13px;
  font-weight: 700;
  color: #ffffff;
}

.footer-info .map-dot {
  margin-left: 2px;
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .main-proximas {
    margin: 0px 0px 80px;
  }

  .title_page {
    margin: 0px 0px -10px;
    padding: 55px 0px 50px;
    background-repeat: repeat;
    background-position: center;
    background-color: rgb(239, 72, 87);
    background-image: url("https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/cpv-fundo-4.png");
    border-radius: 0;
  }

  .title_page h1 {
    font-size: 51px;
    font-weight: 700;
    color: #ffffff;
    text-transform: uppercase;
  }

  .button-toggle {
    margin-top: 0px;
    margin-bottom: 0px;

    background: #f8f8f8;
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;

    padding: 15px 25px;
    height: 56px;

    border: 1px solid #e9e9e9;
    border-radius: 3px;
  }

  .button-toggle span {
    font-size: 15.5px;
    color: rgb(58, 28, 83);
    font-weight: 500;
  }

  .button-toggle span .strong {
    font-weight: 700;
  }

  .button-toggle .button_toggle_icon {
    display: flex;
    flex: auto;
    justify-content: flex-end;
  }

  .button-toggle .button_toggle_icon {
    margin-left: 50px;
    font-size: 16px;
    color: rgb(239, 72, 87);
    font-weight: 300 !important;
  }

  .extensivo-content {
    padding: 20px 20px;
    background-color: #f8f8f8;
    border: 1px solid #e9e9e9;
    border-top: 0;
    border-radius: 3px;
  }

  .extensivo-content .router-link-no-decoration {
    text-decoration: none;
  }

  .extensivo-content .card-course {
    background-color: transparent;
    border: 1px solid #e9e9e9;
    border-radius: 3px;
  }

  .extensivo-content .card-course .header-date {
    padding: 10px;
    margin: 0;
    display: block;
    color: rgb(58, 28, 83);
  }

  .extensivo-content .card-course .header-date span {
    font-size: 14px;
    font-weight: 800;
    color: rgb(58, 28, 83);
  }

  .extensivo-content .card-course .title-course {
    padding: 0px 10px 10px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: left;
  }

  .extensivo-content .card-course .title-course span {
    font-size: 16px;
    font-weight: 700;
    color: rgb(239, 72, 87);
    letter-spacing: -0.004em;
  }

  .footer-info {
    padding: 10px 5px;
    background-color: rgb(239, 72, 87);
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  .footer-info .hour span,
  .location span {
    line-height: 20px;
    font-size: 10px;
    font-weight: 700;
    color: #ffffff;
    /* letter-spacing: 0.02rem; */
  }

  .footer-info .hour i,
  .location i {
    margin-right: 4px;
    line-height: 20px;
    font-size: 13px;
    font-weight: 700;
    color: #ffffff;
  }

  .footer-info .map-dot {
    margin-left: 2px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 768px) {
  .main-proximas {
    margin: -20px 0px 80px;
  }

  .title_page {
    margin: 0px 0px -10px;
    padding: 55px 0px 50px;
    background-repeat: repeat;
    background-position: center;
    background-color: rgb(239, 72, 87);
    background-image: url("https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/cpv-fundo-4.png");
    border-radius: 0;
  }

  .title_page h1 {
    font-size: 51px;
    font-weight: 700;
    color: #ffffff;
    text-transform: uppercase;
  }

  .button-toggle {
    margin-top: 0px;
    margin-bottom: 0px;

    background: #f8f8f8;
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;

    padding: 15px 25px;
    height: 56px;

    border: 1px solid #e9e9e9;
    border-radius: 3px;
  }

  .button-toggle span {
    font-size: 15.5px;
    color: rgb(58, 28, 83);
    font-weight: 500;
  }

  .button-toggle span .strong {
    font-weight: 700;
  }

  .button-toggle .button_toggle_icon {
    display: flex;
    flex: auto;
    justify-content: flex-end;
  }

  .button-toggle .button_toggle_icon {
    margin-left: 50px;
    font-size: 16px;
    color: rgb(239, 72, 87);
    font-weight: 300 !important;
  }

  .extensivo-content {
    padding: 20px 20px;
    background-color: #f8f8f8;
    border: 1px solid #e9e9e9;
    border-top: 0;
    border-radius: 3px;
  }

  .extensivo-content .router-link-no-decoration {
    text-decoration: none;
  }

  .extensivo-content .card-course {
    background-color: transparent;
    border: 1px solid #e9e9e9;
    border-radius: 3px;
  }

  .extensivo-content .card-course .header-date {
    padding: 10px;
    margin: 0;
    display: block;
    color: rgb;
  }

  .extensivo-content .card-course .header-date span {
    font-size: 14px;
    font-weight: 800;
    color: rgb;
  }

  .extensivo-content .card-course .title-course {
    padding: 0px 10px 10px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: left;
  }

  .extensivo-content .card-course .title-course span {
    font-size: 16px;
    font-weight: 700;
    color: rgb(239, 72, 87);
    letter-spacing: -0.004em;
  }

  .footer-info {
    padding: 10px;
    background-color: rgb(239, 72, 87);
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  .footer-info .hour span,
  .location span {
    line-height: 20px;
    font-size: 12px;
    font-weight: 700;
    color: #ffffff;
    /* letter-spacing: 0.02rem; */
  }

  .footer-info .hour i,
  .location i {
    margin-right: 4px;
    line-height: 20px;
    font-size: 13px;
    font-weight: 700;
    color: #ffffff;
  }

  .footer-info .map-dot {
    margin-left: 2px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 576px) {
  .main-proximas {
    margin: 0px 0px 80px;
  }

  .title_page {
    margin: 0px 0px -10px;
    padding: 55px 0px 50px;
    background-repeat: repeat;
    background-position: center;
    background-color: rgb(239, 72, 87);
    background-image: url("https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/cpv-fundo-4.png");
    border-radius: 0;
  }

  .title_page h1 {
    font-size: 51px;
    font-weight: 700;
    color: #ffffff;
    text-transform: uppercase;
  }

  .button-toggle {
    margin-top: 0px;
    margin-bottom: 0px;

    background: #f8f8f8;
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;

    padding: 15px 25px;
    height: 56px;

    border: 1px solid #e9e9e9;
    border-radius: 3px;
  }

  .button-toggle span {
    font-size: 15.5px;
    color: rgb(58, 28, 83);
    font-weight: 500;
  }

  .button-toggle span .strong {
    font-weight: 700;
  }

  .button-toggle .button_toggle_icon {
    display: flex;
    flex: auto;
    justify-content: flex-end;
  }

  .button-toggle .button_toggle_icon {
    margin-left: 50px;
    font-size: 16px;
    color: rgb(239, 72, 87);
    font-weight: 300 !important;
  }

  .extensivo-content {
    padding: 20px 20px;
    background-color: #f8f8f8;
    border: 1px solid #e9e9e9;
    border-top: 0;
    border-radius: 3px;
  }

  .extensivo-content .router-link-no-decoration {
    text-decoration: none;
  }

  .extensivo-content .card-course {
    background-color: transparent;
    border: 1px solid #e9e9e9;
    border-radius: 3px;
  }

  .extensivo-content .card-course .header-date {
    padding: 10px;
    margin: 0;
    display: block;
    color: rgb(58, 28, 83);
  }

  .extensivo-content .card-course .header-date span {
    font-size: 14px;
    font-weight: 800;
    color: rgb(58, 28, 83);
  }

  .extensivo-content .card-course .title-course {
    padding: 0px 10px 10px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: left;
  }

  .extensivo-content .card-course .title-course span {
    font-size: 16px;
    font-weight: 700;
    color: rgb(239, 72, 87);
    letter-spacing: -0.004em;
  }

  .footer-info {
    padding: 10px;
    background-color: rgb(239, 72, 87);
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  .footer-info .hour span,
  .location span {
    line-height: 20px;
    font-size: 12px;
    font-weight: 700;
    color: #ffffff;
    /* letter-spacing: 0.02rem; */
  }

  .footer-info .hour i,
  .location i {
    margin-right: 4px;
    line-height: 20px;
    font-size: 13px;
    font-weight: 700;
    color: #ffffff;
  }

  .footer-info .map-dot {
    margin-left: 2px;
  }
}
</style>
